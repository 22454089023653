import * as React from 'react';

import '../styles/main.scss';
import Footer from './Footer';
import Header from './Header';
import Hero from './Hero';
import OurServices from './OurServices';
import Ourwork from './Ourwork';
import Contactus from './Contactus';
import Head from './Head';
import Testimonials from './Testimonials';
import Brands from './Brands';
import Experience from './Experience';
import Testimonial from './Testimonial';


const IndexPage = () => {
  const [enableDarkMode, setEnableDarkMode] = React.useState(true);
  const toggleDarkMode = () => setEnableDarkMode(!enableDarkMode);

  return (
    <main>
      <Head />
      <div
        className={
          'home-container ' + (enableDarkMode ? 'dark-mode' : 'light-mode')
        }
      >
        <div className='container'>
          <Header toggleDarkMode={toggleDarkMode} />
          <Hero />
          <Brands enableDarkMode={enableDarkMode}/>
          <Experience/>
          <OurServices enableDarkMode={enableDarkMode}/>
          <Ourwork />
          <Testimonial/>
          <Contactus />
          {/* <Testimonials /> */}
          <Footer />
        </div>
      </div>
    </main>
  );
};

export default IndexPage;

import React from "react";
import { Link } from "gatsby";

function Header({ toggleDarkMode }) {
  return (
    <>
      {/* <div className="tw-relative tw-isolate tw-flex tw-justify-center tw-rounded-full tw-mb-4 tw-items-center tw-gap-x-6 tw-overflow-hidden dark:tw-bg-gray-50 tw-bg-gray-200 tw-px-6 tw-py-2.5 tw-sm:px-3.5 tw-sm:before:flex-1">
        <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-x-4 tw-gap-y-2">
          <p className="md:tw-text-lg tw-text-xs tw-leading-6 tw-text-gray-900">
            <span className="tw-font-semibold ">Wipiway</span> is at{" "}
            <a
              href="https://websummit.com/"
              className="tw-text-black tw-underline"
              style={{ color: "black" }}
              target="_blank"
            >
              Websummit
            </a>
            ! Come {" "}
            <a
              className="tw-underline tw-text-xl"
              href="mailto:nj@wipiway.com"
              style={{ color: "black" }}
            >
              Say Hi
            </a>
            .
          </p>
        </div>
      </div> */}

      <section className="header-container tw-flex tw-flex-row tw-items-center">
        {/* <img src={Logo} height="20px" className='logo-img tw-mr-4' /> */}
        <span className="logo">
          <Link to="/">Wipiway</Link>
        </span>

        <div className="tw-hidden lg:tw-block">
          <a
            className="menu-item tw-mr-8"
            href="https://www.upwork.com/freelancers/niranjans"
            target="_blank"
          >
            Upwork
          </a>
          <Link className="menu-item tw-mr-8" to="#our_services">
            Services
          </Link>
          <Link className="menu-item tw-mr-3" to="#our_work">
            Work
          </Link>
          <Link className="menu-item tw-mr-3 contactbar"  to="#contact_us" >
            Contact Us
          </Link>
        </div>

        <span className="toggle-mode">
          <input type="checkbox" id="demo" onChange={toggleDarkMode} />
          <label for="demo"></label>
        </span>
      </section>
    </>
  );
}

export default Header;

import React from 'react'

const Testimonial = () => {
    return (
        <div className='tw-my-20 testimonial_sec'>
            <div className="header_wrapper">
                <div className="services_header our_customers_header ">OUR CUSTOMERS</div>
            </div>


            <div className="header_wrapper tw-mt-14">
                <div className="tw-text-5xl tw-font-semibold sheader_customers">
                    What Our Customers Say

                </div>
            </div>

   <div className="tw-bg-gradient-to-b tw-from-gray-900 tw-to-black tw-text-white tw-py-28 tw-px-4 tw-md:tw-px-8 lg:tw-px-8 header_wrapper tw-rounded-3xl tw-mt-14 md:tw-mx-20 lg:tw-mx-32">
   
   <div className="tw-max-w-2xl tw-mx-auto tw-flex tw-flex-col tw-items-center tw-space-y-12">
        <div className="tw-flex tw-items-center tw-space-x-4">
            <div className="tw-flex tw-flex-col tw-text-center header_wrapper testi_data">
                <p className="tw-text-lg">
                    Working with Wipiway was really helpful! Their expertise in Web3 development is unparalleled. They
                    delivered exceptional results with precision and speed. The team's professionalism and commitment to
                    excellence are commendable. Highly recommended for anyone seeking top-tier Web3 solutions.
                </p>
                <p className="tw-mt-6 tw-text-lg tw-font-semibold">Jamie Parmenter</p>
                <p className="tw-text-lg">CEO - RealNifty</p>
            </div>
        </div>
    </div>
   
</div>

        </div>
    )
}

export default Testimonial


function ChevronLeftIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.5" // Reduced stroke width
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="0.5" />
            <path d="m15 18-6-6 6-6" />
            <line x1="16" y1="12" x2="12" y2="12" stroke="currentColor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/> {/* Trail effect */}
        </svg>
    )
}

function ChevronRightIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.5" // Reduced stroke width
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="0.5" />
            <path d="m9 18 6-6-6-6" />
            <line x1="8" y1="12" x2="12" y2="12" stroke="currentColor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/> {/* Trail effect */}
        </svg>
    )
}
